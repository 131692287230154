import React, { Component, useState } from 'react';
import { string } from 'prop-types';
import { FormattedMessage } from '../../util/reactIntl';
import classNames from 'classnames';
import { propTypes } from '../../util/types';
import { obfuscatedCoordinates } from '../../util/maps';
import { ExternalLink, Heading, Map } from '../../components';

import css from './ListingPage.module.css';

class SectionMapMaybe extends Component {
  constructor(props) {
    super(props);
    this.state = { isStatic: true, setAddressString: '', setPinCode: '' };
  }

  render() {
    const { className, rootClassName, geolocation, publicData, listingId, mapsConfig } = this.props;

    if (!geolocation) {
      return null;
    }

    const address = publicData && publicData.location ? publicData.location.address : '';
    const addressDetails = publicData && publicData?.addressDetails?publicData?.addressDetails:[];

    const classes = classNames(rootClassName || css.sectionMap, className);
    const cacheKey = listingId ? `${listingId.uuid}_${geolocation.lat}_${geolocation.lng}` : null;

    const mapProps = mapsConfig.fuzzy.enabled
      ? {
          obfuscatedCenter: obfuscatedCoordinates(geolocation, mapsConfig.fuzzy.offset, cacheKey),
        }
      : { address, center: geolocation };
    const map = <Map {...mapProps} useStaticMap={this.state.isStatic} />;
 
    // const pinCodeRegex = /\b\d{5}\b/g;
    const filteredDetailsAddress = addressDetails?.filter(
      f =>
        f.id.split('.')?.[0] === 'place'   ||
        f.id.split('.')?.[0] === 'country' ||
        f.id.split('.')?.[0] === 'region'
    );
 
 
 
    const addressArray = filteredDetailsAddress.map(m => m.text)||[];
 
    // console.log(4545, address);
    const fullAdd = addressArray.join(', ');
    
    const countryName = address
      ?.split(',')
      ?.pop()
      ?.trim();
    return (
      <div className={classes}>
        <Heading as="h2" rootClassName={css.sectionHeadingWithExtraMargin}>
          <FormattedMessage id="ListingPage.locationTitle" />
        </Heading>
        {address&&countryName && (
          <div className={css.CountryName}>
            {/* <ExternalLink
              href={`https://www.google.com/maps/search/?api=1&query=${geolocation?.lat},${geolocation?.lng}`}
              target="_blank"
              className={css.location}
            > */}
            {/* {countryName} */}
            {/* {addressDetails?.length > 0 && addressArray.length > 0 ? region?.[0].text+`,`+countryName : countryName} */}
            {addressDetails?.length > 0 && addressArray.length > 0 ? fullAdd : countryName}
            {/* </ExternalLink> */}
          </div>
        )}

        {this.state.isStatic ? (
          <button
            className={css.map}
            onClick={() => {
              this.setState({ isStatic: false });
            }}
          >
            {map}
          </button>
        ) : (
          <div className={css.map}>{map}</div>
        )}
      </div>
    );
  }
}

SectionMapMaybe.defaultProps = {
  rootClassName: null,
  className: null,
  geolocation: null,
  listingId: null,
};

SectionMapMaybe.propTypes = {
  rootClassName: string,
  className: string,
  geolocation: propTypes.latlng,
  listingId: propTypes.uuid,
};

export default SectionMapMaybe;
